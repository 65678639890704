import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Goback from "../assets/image/GoBack.svg";
import DELETE from "../assets/image/Delete.svg";
import Add from "../assets/image/Add.svg";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import ProductHeader from "./ProductHeader";
import InputField from "./InputField";
import { customer } from "../redux/reducer/handleCart";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { getCustomerRegister } from "../api/GlobalApi";
import showIcon from '../assets/image/pasShow.png'
import hiddenIcon from '../assets/image/pashidden.png'
const CustomerRegister = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    defaultValues: {},
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [base64Image, setBase64Image] = useState(null);
  const [errorPassword, setErrorPassword] = useState(false);
  //   console.log("base64Image", base64Image);
  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;

        const retun=base64String.replace("data:image/jpeg;base64,", "");
        setBase64Image(retun);
        //   console.log('base64Image',base64Image);
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };
  // console.log("State", state);

  const { mutate, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/companies/createCompany`,
        postData
      ),
    {
      onSuccess: (data) => {
        console.log('some_value',data);
       alert(data?.data);
        reset()
      navigate('/UserRegisterTable')
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const updata =  useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/companies/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // Handle successful mutation
        alert("Update Successfully!");
        
        reset()
      navigate('/UserRegisterTable')
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  let base64String=state&&state?.companyLogo

  useEffect(() => {
    setValue("customerName", state?.customerName);
    setValue("customerPhoneNumber", state?.customerPhno);
    setValue("customerAltPhoneNumber", state?.customeralternativePhno);
    setValue("shopName", state?.shopName);
    setValue("companyAddress", state?.companyAddress);
    setValue("customerPinCode", state?.companyPincode);
    setValue("companyLogo", state?.companyLogo);
    setValue("companyType", state?.companyType);
    setValue("gstNumber", state?.gstNumber);
    setValue("website", state?.website);
    setValue("email", state?.emailId);
    setValue("password", state?.password);
    setValue("confirmPassword", state?.confirmPassword);
    setValue("role", state?.role);
  }, []);

  const { password, confirmPassword } = watch();
  const onSubmit = (data) => {
    console.log('>>><<<',base64Image===null,base64Image);

    const value = {
      customerName: data.customerName,
      shopName: data.shopName,
      customerPhno: data.customerPhoneNumber,
      customeralternativePhno: data.customerAltPhoneNumber,
      companyAddress: data.companyAddress,
      companyLogo:base64Image===null? state&&state?.companyLogo :base64Image,
      // companyLocation: "City",
      companyPincode: data.customerPinCode,
      companyType: data.companyType,
      emailId: data.email,
      website: data.website,
      gstNumber: data.gstNumber,
      role: data.role,
      password: data.password,
      confirmpassword: data.confirmPassword,
    };
    if (password !== confirmPassword) {
      setErrorPassword(true);
    }else if (state && state.id) {
      updata.mutate(value);
    } else {
      mutate(value);
    }

    // dispatch(customer({ value }));
  };

  const errorSumbit = () => {

    console.log("password", password);
 
  };

  const adminuser = sessionStorage.getItem("user");
  console.log("count_login", base64String);

  const [isImages,setIsImages]=useState(state&&state.companyLogo)
  const handlerImage=()=>{
    const confirmDelete = window.confirm('Are you want to Change the Image?');
    if(confirmDelete){
      setIsImages(false)
    }
   }
   const [isShow,setIsShow]=useState(true)
   const [isPass,setIsPass]=useState(true)
  return (
    <>
      <div className="main-container">
        <ProductHeader back={true} label="Add Customer" />
        <div className="BillingAddMainBlock">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="PurchaseAddFormBlock"
          >
            <div>
              <div
                className="BillingAddInputContainer"
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Customer Name</label>
                  <Controller
                    control={control}
                    name="customerName"
                    render={(field) => (
                      <InputField 
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Customer Name"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Customer Phone Number
                  </label>
                  <Controller
                    control={control}
                    name="customerPhoneNumber"
                    render={(field) => (
                      <InputField
                       customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Phone Number"
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Customer Alt Phone Number
                  </label>
                  <Controller
                    control={control}
                    name="customerAltPhoneNumber"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Alt Phone Number"
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Company Name</label>
                  <Controller
                    control={control}
                    name="shopName"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Company Name"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Company Address{" "}
                  </label>
                  <Controller
                    control={control}
                    name="companyAddress"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Company Address"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Customer Pin Code
                  </label>
                  <Controller
                    control={control}
                    name="customerPinCode"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Customer Pin Code"
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField">
                  <label className="ProductLabel">Product Image</label>
                  {/* <Controller
                    control={control}
                    name="companyLogo"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        className="customerInputField"
                        accept="image/*"
                        type="file"
                        onChange={(e) => onChange(handleImageInputChange(e))}
                        required
                      />
                      //   <InputField   placeholder="0.00 " type="file" />
                    )}
                  /> */}
                   {
                isImages?
                <p className='customerInputField ' onClick={()=>handlerImage()}>{base64String?.substring(5, base64String.indexOf(';')).replace('/', '.')}</p>:
              <Controller 
                control={control}
                name="companyLogo"
                render={({ field: { onChange, onBlur, value, ref } })  => (
                  <input className='customerInputField ' accept='image/*' type='file' onChange={(e)=>onChange(handleImageInputChange(e))}/>
                  // <InputField  {...field} placeholder="0.00 " type="file" />
                )}
              />
              }
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Company type</label>
                  <Controller
                    control={control}
                    name="companyType"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Company type"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">GST Number</label>
                  <Controller
                    control={control}
                    name="gstNumber"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="GST Number"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Website</label>
                  <Controller
                    control={control}
                    name="website"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Website"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">E-Mail</label>
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="E-Mail"
                        type="e-mail"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Password</label>
                  <div onClick={()=>setIsPass(!isPass)} style={{display:'flex',alignItems:'center'}}>

                  <Controller
                    control={control}
                    name="password"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Password"
                        type={`${!isPass?'text':"password"}`}
                      />
                    )}
                  />
                        {/* {
                    !isPass?<img src={showIcon} alt="Show"style={{width:'25px'}}/>:
                    <img src={hiddenIcon} alt="Show" style={{width:'25px'}}/>
                  } */}
                  </div>
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Confirm Password
                  </label>
                  <div onClick={()=>setIsShow(!isShow)} style={{display:'flex',alignItems:'center'}}>

                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={(field) => (
                      <InputField
                      customerInputCss="--customerInputCss"
                        {...field}
                        placeholder="Confirm Password"
                        type={`${!isShow?'text':"password"}`}
                      />
                    )}
                  />
                  {
                    !isShow?<img src={showIcon} alt="Show"style={{width:'25px'}}/>:
                    <img src={hiddenIcon} alt="Show" style={{width:'25px'}}/>
                  }
                  </div>
                  {errorPassword && (
                    <i style={{ color: "red", paddingLeft: "10px" }}>
                      Passwords do not match
                    </i>
                  )}
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Role</label>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="customerInputField"
                        // customerInputCss="--customerInputCss"
                        style={{
                          "text-transform": "capitalize",
                          // borderRadius: "6px",
                          // // border: "none",
                          // padding: "20px 10px",
                        }}
                      >
                        <option>None</option>
                        <option value="admin">Admin</option>
                        {adminuser === "superAdmin" ? (
                          <option value="superAdmin">Super Admin</option>
                        ) : (
                          ""
                        )}
                        <option value="limitedAdmin">Limited Admin</option>
                        required
                      </select>
                    )}
                  />
                </div>
              </div>
              {/*  */}
              <div style={{ padding: "0px 20px 20px", marginBottom: "30px" }}>
                <div>
                  <button
                    type="submit"
                    className="BillingAddSubmitBtn"
                    onClick={() => {
                      errorSumbit();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CustomerRegister;

// customerName : "krishna",
// "shopName": "natchathra",
// "customerPhno": "8945623689",
// "customeralternativePhno": "5869745896",
// "companyAddress": "adyar",
// "companyLogo": "",
// "companyLocation": "City",
// "companyPincode": "600020",
// "companyType": "AquaSystem",
// "emailId": "admin@gmail.com",
// "website": "www.2k virtual World.com",
// "gstNumber": "GST123456789",
// "role": "superAdmin",
// "password": "admin@123",
// "confirmpassword": "admin@123"
