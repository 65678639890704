import React from 'react'
import MultiDashboard from '../component/MultiDashboard'

export default function OverAllDashboard() {
  return (
    <>
      <MultiDashboard />
    </>
  )
}
