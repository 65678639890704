import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PrintIcon from "../assets/image/Print.svg";
import EditIcon from "../assets/image/edit.png";
import IMAGE from "../../src/assets/image/demo.webp";

import DeleteIcon from "../assets/image/trash.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";

const ProductTable = (props) => {
  const [rows, rowchange] = useState([]);
  const [page, pageChange] = useState(0);
  const [rowperpage, rowperpageChange] = useState(6);
  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log(props.searchResult)
  console.log(
    "ProductTable_Azar",
    props?.ProductSearch == "",
    props.searchResult?.length
  );
  console.log('DATA_DELET_filteredData',props&&props?.ProductSearch&&props?.ProductSearch !=='');
  const HandlerDelete= async (itemId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete?');
    if (confirmDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_HOST}/products/${itemId?.id}`,);
        alert('Delete Successfully');
        props?.isRefetching()
        window.location.reload();
      } catch (error) {
        
        console.log('Error',error);
      }
      console.log('Deleted!');
    }
  };
console.log('props&&props',props&&props);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="product_table">
            <TableContainer className="product_table_container_sticky">
              <Table>
                <TableHead className="product_heading_sticky ">
                  <TableRow>
                    <TableCell className="product_table_heading_first_sticky">
                      S.No
                    </TableCell>
                    <TableCell className="product_table_heading_second_sticky">
                      Product ID
                    </TableCell>
                    <TableCell className="product_table_heading_third_sticky">
                      Product Name English
                    </TableCell>
                    {/* <TableCell className="product_table_heading_fourth_sticky">
                      Product Name Tamil
                    </TableCell> */}
                    <TableCell className="product_table_heading_fourth_sticky">
                      Product Image
                    </TableCell>

                    <TableCell className="product_table_heading">
                      Quantity
                    </TableCell>
                    <TableCell className="product_table_heading">
                      Quantity Type
                    </TableCell>
                    <TableCell className="product_table_heading">
                      Unit Price
                    </TableCell>
                    <TableCell className="product_table_heading">
                      Discount Price
                    </TableCell>

                    <TableCell className="product_table_heading">
                      Actual Price
                    </TableCell>
                    <TableCell className="product_table_heading">
                      Offer Select Date
                    </TableCell>

                    <TableCell className="product_table_heading">
                      Status
                    </TableCell>
                    <TableCell className="product_table_heading">
                      ProductCopy
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props&&props?.isLoading?
              
                <TableRow style={{height:'180PX',position:'relative'}}>
                <div class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Loading</span>
    </div>
                </TableRow>
              
              :props&&props?.isError?
              <TableRow>
                  <p style={{color:"#000",fontSize:'18px',fontWeight:800}}>No Network...</p>
                </TableRow>
              :
                    props?.searchProduct?.length === 0 ? (
                    props &&
                    props.data?.data
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((val, i) => {
                        console.log("currentpage_val", val);
                        return (
                          <>
                            <TableRow key={i}>
                              <TableCell className="product_table_data_first_sticky">
                                {i+1}
                              </TableCell>
                              <TableCell className="product_table_data_second_sticky">
                                {val.id}
                              </TableCell>
                              <TableCell className="product_table_data_third_sticky">
                                {val.productNameEnglish}
                              </TableCell>
                              {/* <TableCell className="product_table_data_four_sticky">
                                {val.productNameTamil}
                              </TableCell> */}
                              <TableCell className="product_table_data_four_sticky">
                                <img
                                  src={`data:image/jpeg;base64,${val.productImage}`}
                                  alt="no-image"
                                  className="product_image"
                                ></img>
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.quantity}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.quantityType}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.unitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.discountOnUnitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.actualUnitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                              {moment(val&&val?.offerSelectFromTo).format('DD-MM-YYYY')}
                             
                              </TableCell>
                              <TableCell className="product_table_data">
                                {val.stock}
                              </TableCell>
                              <TableCell className="product_table_data">
                                <img
                                  src={EditIcon}
                                  className="product_edit_icon"
                                  onClick={() =>
                                    navigate("/ProductView/ProductForm", {
                                      state: val,
                                    })
                                  }
                                ></img>
                                <img
                                  src={DeleteIcon}
                                  className="product_delete_icon"
                                  onClick={() =>
                                    HandlerDelete(val)
                                  }
                                ></img>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) :props&&props?.searchResult&&props?.searchResult&&props?.searchResult.length > 0 ? (
                    props &&
                    props?.searchResult
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((e, i) => {
                       
                        return (
                          <>
                            <TableRow key={i}>
                              <TableCell className="product_table_data_first_sticky">
                                {i+1}
                              </TableCell>
                              <TableCell className="product_table_data_second_sticky">
                                {e.id}
                              </TableCell>
                              <TableCell className="product_table_data_third_sticky">
                                {e.productNameEnglish}
                              </TableCell>
                              <TableCell className="product_table_data_four_sticky">
                                {e.productNameTamil}
                              </TableCell>
                              <TableCell className="product_table_data_five_sticky">
                                <img
                                  src={e.productImage}
                                  alt="no-image"
                                  className="product_image"
                                ></img>
                              </TableCell>
                              <TableCell className="product_table_data">
                                {e.quantity}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {e.unitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {e.discountOnUnitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {e.actualUnitPrice}
                              </TableCell>
                              <TableCell className="product_table_data">
                                {moment(e&&e?.offerSelectFromTo).format('DD-MM-YYYY')}
                             
                              </TableCell>
                              <TableCell className="product_table_data">
                                {e.stock}
                              </TableCell>
                              <TableCell className="product_table_data">
                                <img
                                  src={EditIcon}
                                  className="product_edit_icon"
                                  onClick={() =>
                                    navigate("/ProductView/ProductForm", {
                                      state: e,
                                    })
                                  }
                                ></img>
                                <img
                                  src={DeleteIcon}
                                  className="product_delete_icon"
                                  onClick={() =>
                                    HandlerDelete(e)
                                  }
                                ></img>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) : (
                    <h4 style={{width:"190%"}}> No record found</h4>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="container footer_section">
        {props.searchResult?.length == 0 ? (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} to {rowperpage} of {props.data?.length}{" "}
                entries
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props.data?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props.data?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} of{" "}
                {Number(
                  props && props?.data && props?.data?.data.length / 6
                ).toFixed()}{" "}
                entries{" "}
                {Number(
                  props && props?.data && props?.data?.data.length / 6
                ).toFixed() > 1
                  ? "Pages"
                  : "Page"}
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props && props.data?.data.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props && props.data?.data.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductTable;
