import React from 'react'
import DoughnutChart from '../component/DonutChart'
import MyBarChart from '../component/BarChart';
import DashBoard from '../component/DashBoard';

export default function Dashboard() {
  return (
    <div className='container'>
    {/* <div className='row'>

    <div className='col-lg-6'>

    <DoughnutChart/>
    </div>
    <div className='col-lg-6'>
    <DoughnutChart/>
    </div>
    </div>
    <MyBarChart/> */}
    <DashBoard/>
    </div>
  )
}
