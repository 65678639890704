import React, { Component, Fragment, useEffect } from "react";
// import ProductHeader from "./ProductHeader";
import companyLogo from "../assets/image/2k_logo.svg";
import Goback from '../assets/image/GoBack.svg'
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import {useReactToPrint} from 'react-to-print'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import moment from "moment/moment";

export default function BillingInvoice() {
  const navigate=useNavigate();
  const componentRef=useRef()
  const tableHeader = [
    "Product",
    "Unit Price",
    "Discount Price",
    "Quantity",
    "Actual Unit Price",
    
  ];
  const tableData = [
    {
      id: 1,
      CustomerName: "sabari",
      Contact: 91394,
      CustomerAddress: "chennai",
      Pincode: 636121,
      ProductName: "Plastic Chair",
      userForm: [
        {
          Product: "Chair",
          UnitPrice: 1,
          DiscountPrice: 4,
          Quantity: 5,
          ActualUnitPrice: 7,
        },
      ],
    },
    {
      id: 2,
      CustomerName: "sabariraja",
      Contact: 91394,
      CustomerAddress: "chennai",
      Pincode: 636121,
      ProductName: "Outdoor Chair",

      userForm: [
        {
          Product: "Chair",
          UnitPrice: 61,
          DiscountPrice: 43,
          Quantity: 12,
          ActualUnitPrice: 76,
        },
      ],
    },
    {
      id: 3,
      CustomerName: "sabariraja",
      Contact: 91394,
      CustomerAddress: "chennai",
      Pincode: 636121,
      ProductName: "Outdoor Chair",

      userForm: [
        {
          Product: "Chair",
          UnitPrice: 61,
          DiscountPrice: 43,
          Quantity: 12,
          ActualUnitPrice: 76,
        },
      ],
    }
  ];
const handlePrint=useReactToPrint({
  content:()=>componentRef.current,
  documentTitle:'TAX INVOICE',
  // onBeforePrint:()=>alert('printing is going on'),
  // onAfterPrint:()=>alert('printing success')
});
  return (
    <>
    <div>
      <div className="invoice-container">
        <div className="billing_invoice_header">
          <div>
           <img src={Goback} onClick={()=>navigate(-1)}></img>
           </div>
           
           <div className="invoice_head_section">
            <div>
           <h3 className="billing_invoice_heading">Billing Invoice</h3>
           </div>

           <div>
           <input type="submit" value="Print" className="billing_print_btn" onClick={handlePrint}></input>
           </div>
           </div>
        </div>
        <div className="invoice-main-block" ref={componentRef}>
        <div className="TaxInvoice-name">
          <i>TAX INVOICE</i>
        </div>
        <div className="companyName-headerContainer">
          <div>
            <img className="company-logo" src={companyLogo} alt="companyLogo" />
          </div>
          <div className="company-nameAddress">
            <div>
              <span className="company-name"> 2K Inventry </span> <br />
              <span className="company-address">
                No 19 Pillaiyar Kovil street Rajan Nagar <br />
                Kelambakkam 603103 <br />
                9876543210,Web Address
              </span>
            </div>
            <div className="company-dateInvoiceNo">
              <span className="company-date">DATE :</span> {moment.utc().format("DD-MM-YYYY")} <br />
              <span className="company-date">INVOICE NO :</span> 000000
            </div>
          </div>
        </div>
        <div className="company-fromTo-container">
          <div className="company-from-To">
            <span className="company-from"> From :</span> <br />
            No 19 Pillaiyar Kovil street Rajan Nagar <br />
            Kelambakkam 603103 <br />
            9876543210,Web Address
          </div>
          <div className="company-from-To">
            <span className="company-from"> To :</span> <br />
            No 19 Pillaiyar Kovil street Rajan Nagar <br />
            Kelambakkam 603103 <br />
            9876543210,Web Address
          </div>
        </div>
        <div>
          <TableContainer className="invoice-tableContainer">
            <Table>
              <TableHead className="Invoice-tableHeard" >
                <TableRow>
               {
                tableHeader.map((data,i)=>{
                    return(
                        <Fragment key={i}>
                            <TableCell className="invoice-tableName" >
                                {data}
                            </TableCell>
                        </Fragment>
                    )
                })
               }
               </TableRow>
              </TableHead>
              <TableBody className="Invoice-dataTableBody">
                {tableData.map((data,index) => {
                  return (
                    <Fragment key={index}>
                    {data.userForm.map((val,i)=>{
                        return(
                            <Fragment key={i}>
                             <TableRow>
                        <TableCell className="Invoice-dataName">
                          {val.Product}
                        </TableCell>
                        <TableCell className="Invoice-dataName">
                          {val.UnitPrice}
                        </TableCell>
                        <TableCell className="Invoice-dataName">
                          {val.DiscountPrice}
                        </TableCell>
                        <TableCell className="Invoice-dataName">
                          {val.Quantity}
                        </TableCell>
                        <TableCell className="Invoice-dataName" >
                          {val.ActualUnitPrice}
                        </TableCell>
                      </TableRow>
                            </Fragment>
                        )
                    })}
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="invoice-totalContainer">
          <div className="total-text">
            <div className="total-textColum">SUB-TOTAL</div>
            <div className="total-textColum">C-GST 18% </div>
            <div className="total-textColum">S-GST 18% </div>
            <div className="total-textColum">TOTAL</div>
          </div>
          <div className="total-number">
            <div className="total-numberColum">00000</div>
            <div className="total-numberColum">00 </div>
            <div className="total-numberColum">00 </div>
            <div className="total-numberColum">000000</div>
          </div>
        </div>
        <div className="invoice-thanksText">
          <i>THANK YOU FOR YOUR BUSINESS</i>
        </div>
      </div>
      </div>
    </div>
    </>
  );
}
