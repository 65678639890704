import React from 'react'
import ProductViews from '../component/ProductViews'

export default function ProductTable() {
  return (
   <>
   <ProductViews/>
   </>
  )
}
