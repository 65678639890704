import React from 'react'
import BillingView from '../component/BillingView'
export default function BillingTable() {
  const tableData = [
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: " No 3,Kambar st,Thaiyur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "canceled",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "completed",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "incomplete",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "incomplete",
      // billCopy: "NO",
    },
    {
      billId: "123456",
      customerName: "vignesh",
      contact: "1234567890",
      address: "No 89,V.O.C st,Padur",
      salesExecutive: "Azar",
      actualPrice: "2000",
      status: "incomplete",
      // billCopy: "NO",
    },
  ];
  return (
   <>
    <BillingView tableData={tableData}/>
   </>
  )
}
