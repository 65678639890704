import React from 'react'
import BillingAdd from '../component/BillingAdd'

export default function BillingForm() {
  return (<>

      <BillingAdd/>
  </>
  )
}
