import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/global.css'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter, Route, Routes,useParams } from 'react-router-dom';

import store from "./redux/store";
import Sidebars from './component/Sidebar';
import ProductView from './pages/ProductView';
import ProductForm from './pages/ProductForm';
import ProductTable from './pages/ProductTable';
import BillingTable from './pages/BillingTable';
import BillingForm from './pages/BillingForm';
import BillingView from './component/BillingView';
import Dashboard from './pages/Dashboard';
import BillingAdd from './component/BillingAdd';
import BillingInvoice from './pages/BillingInvoice';
import BillingInvoices from './pages/BillingInvoice';
import DashBoard from './component/DashBoard';
import ProductInvoice from './pages/ProductInvoice';
import PurchaseForm from './pages/PurchaseForm';
import OverAllDashboard from './pages/OverAllDashboard';
import PurchaseTable from './pages/PurchaseTable';
import Register from './pages/Register';
import RegisterTable from './pages/RegisterTable';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useSelector } from 'react-redux';
export default function Home() {
    const queryClient = new QueryClient()
    const count = useSelector((state) => state.user);
    console.log("count_login", count&&count?.login);
    const [data, setData] = useState();
    useEffect(()=>{
      setData(localStorage.getItem('login'))
    },[localStorage.getItem('login') ])
    const value = sessionStorage.getItem('login');
    const { slug } = useParams();
    console.log('LoginPAge',slug);
  return (
    <BrowserRouter>
     <QueryClientProvider client={queryClient}>

 

    {value?
    <>
{

}
    <Sidebars />
      <React.StrictMode>
      <Routes>
  
        <Route path="/" element={<Dashboard />} />
        <Route path="/CustomerDashboard" element={<OverAllDashboard />} />
        <Route path="/UserRegister" element={<Register />} />
        <Route path="/UserRegisterTable" element={<RegisterTable />} />
  
        <Route path="/ProductView" element={<ProductView />} />
        <Route path="/ProductView/Invoice" element={<ProductInvoice />} />
        <Route path="/ProductView/ProductForm" element={<ProductForm />} />
        <Route path="/ProductView/ProductTable" element={<ProductTable />} />
        <Route path="/BillingView" element={<BillingView />} />
        <Route path="/Billing/BillingForm" element={<BillingForm />} />
        <Route path="/BillingForm" element={<BillingForm/>}/>
        <Route path="/Billing/BillingTable" element={<BillingTable />} />
        <Route path="/BillingInvoice" element={<BillingInvoice/>}/>
        <Route path="/Purchase/PurchaseForm" element={<PurchaseForm/>}/>
        <Route path="/Purchase/PurchaseTable" element={<PurchaseTable />} />
      </Routes>
    </React.StrictMode>
    </>
      :<>
      <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />} />
        </Routes>
        </React.StrictMode>
      </>
    }

     </QueryClientProvider>
  </BrowserRouter>
  )
}
