import React from 'react'
import PurchaseForms from '../component/PurchaseForm'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
export default function PurchaseForm() {
  const queryClient = new QueryClient()
  return (
    // <QueryClientProvider client={queryClient}>

  <PurchaseForms/>
    // </QueryClientProvider>
  )
}
