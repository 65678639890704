import React from 'react'
import PurchaseViewTable from '../component/PurchaseViewTable'
import PurchaseViews from '../component/PurchaseViews';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
export default function PurchaseTable() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
 <PurchaseViews/>
 </QueryClientProvider>
  )
}
