import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { customer, sales } from "../redux/reducer/handleCart";
import EditIcon from "../assets/image/edit.png";
import DeleteIcon from "../assets/image/trash.png";
import printIcon from "../assets/image/Print.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import store from "../redux/store";

const BillingTable = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [page, pageChange] = useState(0);
  const [rowperpage, rowperpageChange] = useState(6);
  const [sessionValue, setSessionValue] = useState([]);
  const dispatch = useDispatch();
  // const { state } = useLocation()

  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };

  //   const [searchName,setSearchName]=useState("")
  //   const [searchResult,setSearchResult]=useState([])

  //   const handleChange=(e)=>{
  //     setSearchName(e.target.value)
  //   }
  //   const filteredProducts = details?.filter((product)=>{
  //     return(
  //       product.customerName?.toLowerCase().startsWith(searchName?.toLowerCase())
  //     )
  //   }
  //  );
  const HandlerDelete = async (itemId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_HOST}/sales/${itemId?.id}`
        );
        alert("Delete Successfully");
        props && props?.isRefetching();
      } catch (error) {
        console.log("Error", error);
      }
      console.log("Deleted!");
    }
  };

  console.log("vignesh", props.data);
  const handlerPrint = (value) => {
    setSessionValue(value);
    const getStorage = sessionStorage.setItem(
      "myValue",
      JSON.stringify(sessionValue)
    );
    if (getStorage) {
      navigate("/ProductView/Invoice");
    }
    // console.log("value",value);

    // // dispatch(customer({ value }));

    // navigate("/ProductView/Invoice");
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="product_table">
            <TableContainer className="product_table_container_sticky">
              <Table>
                <TableHead className="product_heading_sticky ">
                  <TableRow>
                  {/* <TableCell className="sales_table_heading_first_sticky">
                      S.No
                    </TableCell> */}
                    <TableCell className="sales_table_heading_first_sticky">
                    Product No
                    </TableCell>
                    <TableCell className="sales_table_heading_second_sticky">
                      Customer Name
                    </TableCell>
                    <TableCell className="sales_table_heading_third_sticky">
                      Customer Address
                    </TableCell>
                    <TableCell className="sales_table_heading_fourth_sticky">
                      Contact
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Pincode
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Transaction Mode
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Transaction Status
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Sales Date
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Product
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Unit Price
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Discount Price
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Quantity
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Actual Unit Price
                    </TableCell>
                    <TableCell className="sales_table_heading">
                      Product Copy
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props && props.isLoading ? (
                    <TableRow style={{ height: "180PX", position: "relative" }}>
                      <div class="wrapper">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <span>Loading</span>
                      </div>
                    </TableRow>
                  ) : props && props?.isError ? (
                    <TableRow>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "18px",
                          fontWeight: 800,
                        }}
                      >
                        No Network...
                      </p>
                    </TableRow>
                  ) : props.searchName?.length === 0 ? (
                    props.data
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((val, i) => {
                        {console.log("val_Ssss>>>>",val)}
                        return (
                          <>
                            <TableRow key={i}>
                            {/* <TableCell className="sales_table_data_first_sticky">
                                {i+1}
                              </TableCell> */}
                              <TableCell className="sales_table_data_first_sticky">
                                {val.id}
                              </TableCell>
                              <TableCell className="sales_table_data_second_sticky">
                                {val.customerName}
                              </TableCell>
                              <TableCell className="sales_table_data_third_sticky">
                                {val.customerAddress}
                              </TableCell>
                              <TableCell className="sales_table_data_fourth_sticky">
                                {val.contactNumber}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.pincode}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.transactionMode}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.transactionStatus}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.salesDate}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.productName}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.unitPrice}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.discountPrice}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.quantity}
                              </TableCell>
                              <TableCell className="sales_table_data">
                                {val.actualPrice}
                              </TableCell>

                              <TableCell className="sales_table_data">
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={EditIcon}
                                  alt="Edit icon"
                                  className="sales_edit_icon"
                                  onClick={() =>
                                    navigate("/BillingForm", { state: val })
                                  }
                                />
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={DeleteIcon}
                                  alt="Delete icon"
                                  className="sales_delete_icon"
                                  onClick={() => HandlerDelete(val)}
                                />
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={printIcon}
                                  alt="print icon"
                                  className="sales_print_icon"
                                  value={val}
                                  onClick={
                                    () => {
                                      //  navigate(
                                      //   "/ProductView/Invoice")
                                      sessionStorage.setItem(
                                        "myValue",
                                        JSON.stringify(val)
                                      );
                                      navigate("/ProductView/Invoice");
                                    }
                                    // navigate(
                                    //   "/ProductView/Invoice",
                                    //   sessionStorage.setItem(
                                    //     "myValue",
                                    //     JSON.stringify(val)
                                    //   )
                                    // )
                                    // handlerPrint(val)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) : props.searchResult?.length > 0 ? (
                    props.searchResult
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((val, i) => {
                        return (
                          <>
                            <TableRow key={i}>
                            <TableCell className="sales_table_data_first_sticky">
                            {val.id}
                              </TableCell>
                            <TableCell className="sales_table_data_second_sticky">
                            {val.customerName}
                              </TableCell>
                              <TableCell className="sales_table_data_third_sticky">
                              {val.customerAddress}
                              </TableCell>
                              <TableCell className="sales_table_data_fourth_sticky">
                              {val.contactNumber}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.pincode}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.transactionMode}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.transactionStatus}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.salesDate}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.productName}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.unitPrice}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.discountPrice}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.quantity}
                              </TableCell>
                              <TableCell className="sales_table_data">
                              {val.actualPrice}
                              </TableCell>
                             

                              <TableCell className="sales_table_data">
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={EditIcon}
                                  alt="Edit icon"
                                  className="sales_edit_icon"
                                  onClick={() =>
                                    navigate("/BillingForm", { state: val })
                                  }
                                />
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={DeleteIcon}
                                  alt="Delete icon"
                                  className="sales_delete_icon"
                                  onClick={() => HandlerDelete(val)}
                                />
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={printIcon}
                                  alt="print icon"
                                  className="sales_print_icon"
                                  value={val}
                                  onClick={
                                    () => {
                                      sessionStorage.setItem(
                                        "myValue",
                                        JSON.stringify(val)
                                      );
                                      navigate("/ProductView/Invoice");
                                    }

                                    // navigate("/ProductView/Invoice")
                                    // handlerPrint(val)
                                    // setSessionValue(val)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) : (
                    <h4>No record found</h4>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="container footer_section">
        {props.searchName?.length === 0 ? (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} of{" "}
                {Math.ceil(props.data?.length / rowperpage)} pages
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props.data?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props.data?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        ) : props.searchName?.length !== 0 ? (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} of{" "}
                {Math.ceil(props.searchResult?.length / rowperpage)} pages
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props.searchResult?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props.searchResult?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        ) : (
          <h5>no record</h5>
        )}
      </div>
    </>
  );
};

export default BillingTable;
