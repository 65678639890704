import React from "react";
import BackIcon from "../assets/productViewImages/Back-icon.png";
import { useNavigate } from "react-router-dom";


export default function ProductHeader(props) {
  const navigate = useNavigate();
  console.log('props?.name',props?.name);
  return (
    <div className="productViewMainContainer-header">
      {props.back ? (
        <div>
          <img
            className={`Add-product-GoBack-image ${props?.backButtonClassName} `} 
            onClick={() => {navigate(-1)}}
            src={BackIcon}
            alt="Go Back"
          />
        </div>
      ) : (
        <></>
      )}

      <div className={`productViewContainer-header ${props?.dashBoardHeader}`}>
        <div className={`productView-name ${props?.name} `}>{props.label}</div>
        {props.productView ? (
          <div>
            {/* <Button type='submit' value='Submit'/> */}
            <div onClick={()=>{
              navigate("/ProductView/ProductTable")
            }} className="ProductView-btn">ProductView</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
