import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Logo from "/assets/image/Go Back.svg";
import Goback from "../assets/image/GoBack.svg";
import DELETE from "../assets/image/Delete.svg";
import Add from "../assets/image/Add.svg";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingAddInputField from "./BillingAddInputField";
import BillingAddPriceInputField from "./BillingAddPriceInputField";
import { Alert } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { getSalesDetail } from "./BillingView";
import Sidebars from "./Sidebar";
import { getPurchaseProduct } from "../api/GlobalApi";
import DatePicker from "react-datepicker";
import BillingAddProductField from "./BillingAddProductField";
const BillingAdd = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: "userForm",
      control,
    }
  );

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("searchName : ", props.searchName);
  console.log("searchResult : ", props.searchResult);
  console.log("Billing_state", state?.productName);
  useEffect(() => {
    setValue("customerName", state?.customerName);
    setValue("contactNumber", state?.contactNumber);
    setValue("customerAddress", state?.customerAddress);
    setValue("pincode", state?.pincode);
    setValue("transactionMode", state?.transactionMode);
    setValue("transactionStatus", state?.transactionStatus);
    setValue("productName", state?.productName);
    setValue("unitPrice", state?.unitPrice);
    setValue("discountPrice", state?.discountPrice);
    setValue("quantity", state?.quantity);
    setValue("actualPrice", state?.actualPrice);
    setValue("salesDate", state?.salesDate);
    // useEffect(() => {
    //   setFilter(
    //     status === "All" ? Data:Data .filter((dt) => {
    //      return dt.Status === status
    //     })
    //   );
  }, [state]);
  const getSalesDetails = useQuery(
    "getPurchaseProductBillingAddProduct",
    getPurchaseProduct
  );

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    // First argument: function that makes the actual request
    // Second argument (optional): Config object
    (postData) => axios.post(`${process.env.REACT_APP_HOST}/sales`, postData),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // Handle successful mutation

        alert("Data Added Successfully!");
        navigate("/BillingView");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );

  const postformdata = useMutation(
    (postData) =>
      axios.put(`${process.env.REACT_APP_HOST}/sales/${state.id}`, postData),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // Handle successful mutation
        alert("Update Successfully!");
        navigate("/BillingView");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    console.log("FFFF_FFFF_data", data);
    let formData;
    state && state.id !== null
      ? (formData = {
          customerName: data?.customerName,
          quantity: Number(data?.quantity),
          customerAddress: data?.customerAddress,
          discountPrice: data?.discountPrice,
          unitPrice: data?.unitPrice,
          pincode: data?.pincode,
          productName: data?.productName,
          contactNumber: data?.contactNumber,
          actualPrice: data?.actualPrice,
          transactionMode: data?.transactionMode,
          transactionStatus: data?.transactionStatus,
          salesDate: data?.salesDate ? data?.salesDate : new Date(),
          total: data?.actualPrice,
          id: state?.id,
        })
      : (formData = {
          customerName: data?.customerName,
          quantity: Number(data?.quantity),
          customerAddress: data?.customerAddress,
          discountPrice: data?.discountPrice,
          contactNumber: data?.contactNumber,
          unitPrice: data?.unitPrice,
          pincode: data?.pincode,
          productName: data?.productName,
          actualPrice: data?.actualPrice,
          transactionMode: data?.transactionMode,
          transactionStatus: data?.transactionStatus,
          total: data?.actualPrice,
          // id: state?.id,
          salesDate: data?.salesDate ? data?.salesDate : new Date(),
        });
    console.log("BillingData", formData);
    if (state && state?.id) {
      postformdata.mutate(formData);
    } else {
      mutate(formData);
    }
  };
  let newArray =
    getSalesDetails.data &&
    getSalesDetails?.data?.data.filter((item) => item !== null);
  console.log("New Array ", newArray, getSalesDetails);

  const { transactionMode } = watch();
  console.log("PurchangeForm_load_billing", transactionMode);
  useEffect(() => {
    setValue(
      "transactionStatus",
      transactionMode === "credit" ? "Pending" : "Completed"
    );
  }, [transactionMode]);
  // const{TransactionMode}=watch()
  // console.log("TransactionMode",TransactionMode);

  const { unitPrice, discountPrice, quantity } = watch();
  const addSalesValues = unitPrice * quantity - discountPrice;
  useEffect(() => {
    if (addSalesValues > 0) {
      setValue("actualPrice", addSalesValues);
    } else {
      setValue("actualPrice", 0);
    }
  });

  return (
    <>
      <div className="main-container">
        <div className="container-lg">
          <div className="BillingAddHeader">
            <div className="Billing-Add-GoBackBtn">
              <img src={Goback} alt="no-image" onClick={() => navigate(-1)} />
            </div>

            <div className="billing-heading-viewblock">
              <div>
                <h3 className="Billng-Add-Heading">Sales Add</h3>
              </div>

              <div className="Billing-Add-ViewBlock">
                <button
                  className="Billing-Add-ViewBtn"
                  onClick={() => {
                    navigate("/BillingView");
                  }}
                >
                  View Sales
                </button>
              </div>
            </div>
          </div>
          <div
            className="BillingAddMainBlock"
            style={{ border: "none", overflow: "hidden" }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="BillingAddFormBlock"
            >
              <div
                style={
                  {
                    // "text-transform": "capitalize",
                    // borderRadius: "5px",
                    // border: "none",
                    // padding: "5px 10px",
                  }
                }
              >
                <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                >
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Customer Name
                    </label>
                    <Controller
                      control={control}
                      name="customerName"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Customer Name"
                          type="text"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">
                      Customer Address
                    </label>
                    <Controller
                      control={control}
                      name="customerAddress"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Customer Address"
                          type="text"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Pincode</label>
                    <Controller
                      control={control}
                      name="pincode"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="603103"
                          type="tele"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                >
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Contact</label>
                    <Controller
                      control={control}
                      name="contactNumber"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="contact"
                          type="tele"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Transaction Mode
                    </label>
                    <Controller
                      control={control}
                      name="transactionMode"
                      defaultValue="Cash"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="BillingAddStatus"
                          placeholder="Select"
                          style={{
                            "text-transform": "capitalize",
                            // borderRadius: "5px",
                            // border: "none",
                            // padding: "5px 10px",
                          }}
                        >
                          <option>None</option>
                          <option value="Cash">Cash</option>
                          <option value="Online">Online</option>
                          <option value="credit">Credit</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Transaction Status
                    </label>
                    <Controller
                      control={control}
                      name="transactionStatus"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="BillingAddStatus"
                          placeholder="Select"
                          style={{
                            "text-transform": "capitalize",
                          }}
                        >
                          <option>None</option>
                          <option value="Completed">completed</option>
                          <option value="Pending">pending</option>
                        </select>
                      )}
                    />
                  </div>
                </div>
                <div
                  className="BillingAddInputField"
                  style={{ paddingRight: "12px" }}
                >
                  <label className="BillingAddInputLabel">Sales Date</label>
                  <Controller
                    control={control}
                    name="salesDate"
                    render={({ field }) => (
                      <DatePicker
                        className="billing_input_field "
                        {...field}
                        placeholderText="Sales Date"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                  />
                </div>

                <div>
                  {/* <div className="BillingAddAppendMainBlock"> */}
                  <div className="BillingAddAppendField">
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">Product</label>
                      <Controller
                        control={control}
                        name="productName"
                        render={(field) => (
                          <BillingAddProductField
                            {...field}
                            placeholder="Search Product"
                            type="text"
                            list="products"
                            // onChange={handleChange}
                          />
                        )}
                      />
                      <datalist id="products">
                        {newArray?.map((product) => {
                          // console.log("product name : ",product)
                          return <option value={product}></option>;
                        })}
                      </datalist>
                      {/* <input {...register(`userForm.${index}.Product`)} ></input> */}
                    </div>

                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Unit Price
                      </label>
                      <Controller
                        control={control}
                        name="unitPrice"
                        // name={`userForm.${index}.UnitPrice`}
                        render={(field) => (
                          <BillingAddProductField
                            // billingInputCss="--billingInputCss"
                            // className='billingAddProductField'
                            {...field}
                            placeholder="0.00"
                            type="number"
                          />
                        )}
                      />
                      {/* <input {...register()} type="number"></input> */}
                    </div>

                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Discount Price
                      </label>
                      <Controller
                        control={control}
                        // name={`userForm.${index}.DiscountPrice`}
                        name="discountPrice"
                        className="discount"
                        render={(field) => (
                          <BillingAddProductField
                            {...field}
                            placeholder="0.00"
                            type="number"
                          />
                        )}
                      />
                      {/* <input {...register(`userForm.${index}.DiscountPrice`)} type="number"></input> */}
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels ">Quantity</label>
                      <Controller
                        control={control}
                        // name={`userForm.${index}.Quantity`}
                        name="quantity"
                        render={(field) => (
                          <BillingAddProductField
                            {...field}
                            placeholder="0.00"
                            type="number"
                          />
                        )}
                      />
                      {/* <input {...register(`userForm.${index}.Quantity`)} type="number"></input> */}
                    </div>

                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Actual Unit Price
                      </label>
                      <Controller
                        control={control}
                        // name={`userForm.${index}.ActualUnitPrice`}
                        name="actualPrice"
                        render={(field) => (
                          <BillingAddProductField
                            {...field}
                            placeholder="0.00"
                            type="number"
                          />
                        )}
                      />
                    </div>

                    {/* {index > 0 ? (
                                  <>
                                    <img
                                      src={DELETE}
                                      alt="DELETE"
                                      {...register(`userForm.${index}.image`)}
                                      onClick={() => remove(index)}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )} */}
                  </div>
                  {/* );
                          })} */}
                </div>
                {/* <div className="col-1">
                          <img
                            src={Add}
                            className="BillingAdd-AddBtn"
                            onClick={() => {
                              append({
                                Product: "select",
                                UnitPrice: 0,
                                DiscountPrice: 0,
                                Quantity: 0,
                                ActualUnitPrice: 0,
                              });
                            }}
                          ></img>
                        </div> */}
                <div />
              </div>
              <div className="BillingAddSubmitBlock">
                <div>
                  <button type="submit" className="BillingAddSubmitBtn">
                    Submit
                  </button>
                </div>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAdd;
