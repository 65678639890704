import React from "react";
import ProductHeader from "./ProductHeader";
import { useLocation } from "react-router-dom";
import SingleBarchart from "./SingleBarchart";

export default function MultiDashboard(props) {
  const { state } = useLocation();
  // console.log('state',state);
  const MultiDashboard = (value) => {
    console.log("Switch", value);
    switch (value?.value) {
      case "SalesDashboard":
        let data = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label:'SalesDashboard',
              backgroundColor: 'rgba(75,192,192,1)',
             
              borderWidth: 2,
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        };
        return (<SingleBarchart data={data}/>)
      case "Purchase Dashboard":
        let value = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label:'Purchase Dashboard',
              backgroundColor: '#C386B9',
              borderWidth: 2,
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        };
        return (<SingleBarchart data={value}/>)
      case "Revenue Dashboard":
        let Revenue = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label:'Revenue Dashboard',
              backgroundColor: '#FBB06F',
              borderWidth: 2,
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        };
        return (<SingleBarchart data={Revenue}/>)
      case "Credit Dashboard":
        let Credit = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label:'Credit Dashboard',
              backgroundColor: '#F18B99',
              borderWidth: 2,
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        };
        return (<SingleBarchart data={Credit}/>)
      case "Debit Dashboard":
        let Debit = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label:'Debit Dashboard',
              backgroundColor: '#1BCFB4',
              borderWidth: 2,
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        };
        return (<SingleBarchart data={Debit}/>)
      default:
        return <h3>Sales</h3>;
    }
  };

  return (
    <div className="dashboard-main-container">
      <ProductHeader
        back={true}
        label={state}
        name="--Stock-dashboard"
        backButtonClassName="--back-button"
      />
      <div className="input-container">
        <MultiDashboard value={state} />
      </div>
    </div>
  );
}
