import React from 'react'
import SignIn from './pages/SignIn';


const App = () => {
  const data = [10, 20, 30, 40, 50]; 
  const labels = ['A', 'B', 'C', 'D', 'E'];
  return (
    <>
<SignIn/>
    </>
  )
}

export default App