import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import PrintIcon from '../assets/image/Print.svg'
import EditIcon from '../assets/image/edit.png'
import DeleteIcon from '../assets/image/trash.png'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
const ProductTable = (props) => {
  const [rows,rowchange]=useState([])
  const [page,pageChange]=useState(0)
  const [rowperpage,rowperpageChange]=useState(6)
  let  handlepageChange=(event,newpage)=>{
    pageChange(newpage)
  }
  let handlerowsperpageChange=(event)=>{
rowperpageChange(+event.target.value)
pageChange(0)
  }
  const navigate=useNavigate();
console.log('DATA_FETCH',props);

const HandlerDelete= async (itemId) => {
  const confirmDelete = window.confirm('Are you sure you want to delete?');
    if (confirmDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_HOST}/purchases/${itemId?.id}`,);
        alert('Delete Successfully')
        props&&props?.isRefetching()
      } catch (error) {
        
        console.log('Error',error);
      }
      console.log('Deleted!');
    }
};


console.log('ddd',rowperpage*(page+1))
  return (
    <>

<div className='container'>
  <div className='row'>
    <div className='product_table'>

  <TableContainer  className='product_table_container_sticky'>
    <Table >
       <TableHead className='product_heading_sticky ' >
            <TableRow >
            <TableCell className='purchase_table_heading_first_sticky'>Product id</TableCell>
            <TableCell className='purchase_table_heading_second_sticky'>Product Name</TableCell>
            <TableCell className='purchase_table_heading_third_sticky'>Quantity</TableCell>
            {/* <TableCell className='product_table_heading_fourth_sticky' >Product Name Tamil</TableCell>
            <TableCell className='product_table_heading_five_sticky'>Product Image</TableCell> */}
            
            <TableCell className='product_table_heading'>Quantity Type</TableCell>
            <TableCell className='product_table_heading'>Purchase Date</TableCell>
            <TableCell className='purchase_table_heading'>PurchaseAmount</TableCell>
            <TableCell className='purchase_table_heading'>Transport</TableCell>
            
            <TableCell className='purchase_table_heading'>GST</TableCell>
            <TableCell className='purchase_table_heading'>OtherAmount</TableCell>


            <TableCell className='purchase_table_heading'>Total Amount</TableCell>
            <TableCell className='purchase_table_heading'>InvoiceNumber</TableCell>
            <TableCell className='purchase_table_heading'>Transaction Mode</TableCell>
            <TableCell className='purchase_table_heading'>Transaction Status</TableCell>
            <TableCell className='purchase_table_heading'>Product Copy</TableCell>
            </TableRow>
        </TableHead>
<TableBody >
      {
        props&&props?.isLoading?
              
        <TableRow style={{height:'180PX',position:'relative'}}>
        <div class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Loading</span>
    </div>
        </TableRow>
      
      : props&&props?.isError?
      <TableRow>
          <p style={{color:"#000",fontSize:'18px',fontWeight:800}}>No Network...</p>
        </TableRow>
      :(props.searchProduct?.length===0)?(
        props.data?.slice(page * rowperpage,page * rowperpage + rowperpage).map((val,i)=>{
              return (
                <>
                <TableRow key={i} >
                <TableCell className='purchase_table_data_first_sticky'>{val.id}</TableCell>
                <TableCell className='purchase_table_data_second_sticky'>{val.productName}</TableCell>
                <TableCell className='purchase_table_data_third_sticky'>{val.quantity}</TableCell>
                <TableCell className='purchase_table_data'>{val.quantityType}</TableCell>
                <TableCell className='purchase_table_data'>{val.purchaseDate}</TableCell>
                <TableCell className='purchase_table_data'>{val.purchaseAmount}</TableCell>
                <TableCell className='purchase_table_data'>{val.transport}</TableCell>
                <TableCell className='purchase_table_data'>{val.gst}</TableCell>
                <TableCell className='purchase_table_data'>{val.otherAmount}</TableCell>
                <TableCell className='purchase_table_data'>{val.total}</TableCell>
                <TableCell className='purchase_table_data'>{val.invoiceNumber}</TableCell>
                <TableCell className='purchase_table_data'>{val.transactionMode}</TableCell>
                <TableCell className='purchase_table_data'>{val.transactionStatus}</TableCell>
                <TableCell className="purchase_table_data">
                <img src={EditIcon} className='product_edit_icon' onClick={()=>navigate("/Purchase/PurchaseForm",{state:val})}></img>
                <img src={DeleteIcon} className='product_delete_icon' onClick={()=>HandlerDelete(val)}></img>
                        </TableCell>
                </TableRow>
                </>
              )
            })
        ):(props.searchResult?.length > 0) ?(
          props.searchResult?.slice(page * rowperpage,page * rowperpage + rowperpage).map((val,i)=>{
            return (
              <>
              <TableRow key={i} >
              <TableCell className='purchase_table_data_first_sticky'>{val.id}</TableCell>
              <TableCell className='purchase_table_data_second_sticky'>{val.productName}</TableCell>
              <TableCell className='purchase_table_data_third_sticky'>{val.quantity}</TableCell>
              <TableCell className='purchase_table_data'>{val.purchaseDate}</TableCell>
              <TableCell className='purchase_table_data'>{val.purchaseAmount}</TableCell>
              <TableCell className='purchase_table_data'>{val.transport}</TableCell>
              <TableCell className='purchase_table_data'>{val.gst}</TableCell>
              <TableCell className='purchase_table_data'>{val.otherAmount}</TableCell>
              <TableCell className='purchase_table_data'>{val.total}</TableCell>
              <TableCell className='purchase_table_data'>{val.invoiceNumber}</TableCell>
              <TableCell className='purchase_table_data'>{val.transactionMode}</TableCell>
              <TableCell className='purchase_table_data'>{val.transactionStatus}</TableCell>
              <TableCell className="purchase_table_data">
              <img src={EditIcon} className='product_edit_icon' onClick={()=>navigate("/Purchase/PurchaseForm",{state:val})}></img>
              <img src={DeleteIcon} className='product_delete_icon' onClick={()=>HandlerDelete(val)}></img>
                      </TableCell>
              </TableRow>
              </>
            )
          })
          ):(
            <TableRow>
{/* <TableCell style={{width:'100%'}}> */}

              <h4 style={{width:'205%'}}>No record found</h4>
{/* </TableCell> */}
            </TableRow>
          )
          }
                  
      </TableBody>
    </Table>
  </TableContainer>
  
  </div>
  </div>
  </div>
  
  <div className='container footer_section'>
{
    (props.searchProduct?.length===0)?(
      <div className='row mt-3'>
    <div className='col-6'>
  <p className='product_paragraph_footer'>Showing  {page+1} of {Number(Math.ceil((props.data?.length)/rowperpage))} pages </p>
  </div>
  <div className='col-6'>
    
  <TablePagination
 
 rowsPerPageOptions={[props.data?.length]}
  rowsPerPage={rowperpage}
  page={page}
  count={props.data?.length}
  component="div"
  onPageChange={handlepageChange}
  onRowsPerPageChange={handlerowsperpageChange}

  >
  </TablePagination>
  </div>
  </div>
    ):(props.searchProduct?.length !==0) ?(
      <div className='row mt-3'>
      <div className='col-6'>
    <p className='product_paragraph_footer'>Showing  {page+1} of {Number(Math.ceil((props.searchResult?.length)/rowperpage))} pages </p>
    </div>
    <div className='col-6'>
      
    <TablePagination
   
   rowsPerPageOptions={[props.searchResult?.length]}
    rowsPerPage={rowperpage}
    page={page}
    count={props.searchResult?.length}
    component="div"
    onPageChange={handlepageChange}
    onRowsPerPageChange={handlerowsperpageChange}
  
    >
    </TablePagination>
    </div>
    </div>
    ):(
      <h4>No data found</h4>
    )
}
    
  </div>
    </>
  )
}

export default ProductTable

