import React from 'react'
import BillingInvoice from '../component/BillingInvoice'

export default function BillingInvoices() {
  return (
    <>
    <BillingInvoice/>
    </>
  )
}
