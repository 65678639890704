// // import React, { useEffect } from 'react';
// // import Highcharts from 'highcharts';

// // const DonutChart = () => {
// //     function getSubtitle() {
// //         // const totalNumber = getData(input.value)[0][1].toFixed(2);
// //         return `            <span style="font-size: 28px","font-weight: 900;">
// //               50%
// //             </span>`;
// //     }
// //     useEffect(() => {
// //         // Define your chart options
// //         const options = {
// //             chart: {
// //                 type: 'pie',
// //                 plotBackgroundColor: null,
// //                 plotBorderWidth: null,
// //                 plotShadow: false
// //             },
// //             title: {
// //                 text: 'Donut Chart'
// //             },
// //             subtitle: {
// //                 useHTML: true,
// //                 text: getSubtitle(),
// //                 floating: true,
// //                 verticalAlign: 'middle',
// //                 y: 30
// //             },
// //             plotOptions: {
// //                 pie: {
// //                     allowPointSelect: true,
// //                     cursor: 'pointer',
// //                     dataLabels: {
// //                         enabled: true,
// //                         format: '<b>{point.name}</b>: {point.percentage:.1f} %'
// //                     },
// //                     innerSize: '50%' // This makes it a donut chart
// //                 }
// //             },
// //             colors: ['#D0D31F', '#ffff', ],
            
// //             series: [{
// //                 name: 'Brands',
// //                 colorByPoint: true,
// //                 data: [{
// //                     name: '',
// //                     y: 61.41,

// //                     sliced: true,
// //                     selected: true
// //                 }, 
// //                 {
// //                     name:'',
// //                     y:38.9,

// //                 }
               
// //             ]
// //             }]
// //         };

// //         // Render the chart
// //         Highcharts.chart('donut-chart-container', options);
// //     }, []);

// //     return (
// //         <div id="donut-chart-container" style={{ width: '100%', height: '400px' }}></div>
// //     );
// // };

// // export default DonutChart;
// // // import React from 'react';
// // // import { Doughnut } from 'react-chartjs-2';

// // // const DonutChart = () => {
// // //   // Sample data
// // //   const data = {
// // //     labels: ['Red',],
// // //     datasets: [
// // //       {
// // //         data: [12,],
// // //         backgroundColor: [
// // //           '#FF6384',
   
// // //         ],
// // //         hoverBackgroundColor: [
// // //           '#FF6384',
   
// // //         ],
// // //       },
// // //     ],
// // //   };

// // //   // Chart options
// // //   const options = {
// // //     cutoutPercentage: 70, // Custom circumference percentage
// // //   };

// // //   return <Doughnut data={data} options={options} />;
// // // };

// // // export default DonutChart;
// import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { Doughnut } from 'react-chartjs-2';

// const DonutChart = (props) => {
//   console.log('====================================');
//   console.log("VALUE",props&&props?.value);
//   console.log('====================================');
//   const [reportValue, setReportValue] = useState();
//   console.log("VALUE",props&&props?.value);
//   const[values,setValue]=useState(props&&props?.value)
//   useEffect(()=>{
//     setValue(props&&props?.value)
//   },[props&&props?.value])
//   console.log('====================================');
//   console.log("values_values",typeof(+values));
//   console.log('====================================');
// const data = {
//     labels: ['Sales'],

       
//     datasets: [
//       {
//       //   label: '# of Votes',
//         // data: [+values, 100],
//         data: [+values,10],
//         backgroundColor: [
//           props?.color,'#fff'
//         ],
//         borderColor: [
//           props?.color,
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };
  
//   const options = {
    
//     plugins: {
//           datalabels: {
//             display: false
//           },
//       doughnutlabel: {
//         labels: [
//           {
//             text: 'Total',
//             font: {
//               size: '20',
//               weight: 'bold',
//             },
//           },
//           {
//             text: '34',
//             font: {
//               size: '30',
//               weight: 'bold',
//             },
//           },
//         ],
//       },
//       legend: {
//           display: false,
//         },
        
//     },
//   };

//   useEffect(() => {
//     console.log("props?.value", props?.value);
//     let value;
//     if (props?.value >= 1000 && props?.value < 1000000) {
//       value = props?.value / 1000;
//       setReportValue(Math.round(value) + "" + "K");
//       console.log("valueIF", value);
//     } else if (props?.value >= 1000000 && props?.value < 100000000) {
//       value = props?.value / 1000000;
//       setReportValue(Math.round(value) + "" + "M");
//       console.log("valueElseIf", value);
//     }else if (props?.value < 1000) {
//       setReportValue(props?.value)
//     }
//   }, [props?.value]);
//   return (
//     <div style={{ position: 'relative',height:'200px',width:'200px',padding:'10px',overflow:'hidden' }}>
//     <Doughnut data={data} options={options} />
//     <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
//       <h3 style={{color:props?.color}}>{reportValue}</h3>
//     </div>
//   </div>  
// )
// };

// export default DonutChart;
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const DonutChart = (props) => {
  console.log("props?.value",props?.value);
  let amount=38800;
  const [reportValue, setReportValue] = useState('');
  const [chartData, setChartData] = useState({
    labels: ['Sales'],
    datasets: [
      {
        data: [0, 100], // Default data
        backgroundColor: [props?.color, '#fff'],
        borderColor: [props?.color],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    let value = props&&props?.value || 0; // Ensure value is a number
    console.log('====================================');
    console.log("USEF",typeof(value,props&&props?.value)  );
    console.log('====================================');
    setChartData({
      labels: ['Sales'],
      datasets: [
        {
          data: [props&&props?.value, 1],
          backgroundColor: [props?.color, '#fff'],
          borderColor: [props?.color],
          borderWidth: 1,
        },
      ],
    });

    let displayValue;
    if (value >= 1000 && value < 1000000) {
      displayValue = (value / 1000).toFixed(1) + 'K';
    } else if (value >= 1000000) {
      displayValue = (value / 1000000).toFixed(1) + 'M';
    } else {
      displayValue = value;
    }
    setReportValue(displayValue);
  }, [props?.value, props?.color]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '70%', // This makes it a donut chart
  };

  return (
    <div style={{ position: 'relative', height: '200px', width: '200px', padding: '10px', overflow: 'hidden' }}>
      <Doughnut data={chartData} options={options} />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        <h3 style={{ color: props?.color }}>{reportValue}</h3>
      </div>
    </div>
  );
};

export default DonutChart;
