import React, { useEffect } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Logo from "/assets/image/Go Back.svg";
import Goback from "../assets/image/GoBack.svg";
import DELETE from "../assets/image/Delete.svg";
import Add from "../assets/image/Add.svg";
// import {useQueryClient, useMutation, useQuery} from '@tanstack/react-query';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingAddInputField from "./BillingAddInputField";
import BillingAddPriceInputField from "./BillingAddPriceInputField";
import { Alert } from "@mui/material";
import {
  SharePurchaseForm,
  UsePurchaseForm,
  getPurchaseProduct,
  getPurchaseProducts,
} from "../api/GlobalApi";
// import { Axios } from "axios";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import DatePicker from "react-datepicker";
import BillingAddProductField from "./BillingAddProductField";
import moment from "moment/moment";
const PurchaseForm = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("State_Form", state);

  // const onSubmit = async(data) => {
  //   await UsePurchaseForm(data);
  //   console.log("data_PUR", data);
  //   // navigate("/BillingInvoice");
  // };

  useEffect(() => {
    setValue("productName", state && state?.productName);
    setValue("quantity", state && state?.quantity);
    setValue("quantityType", state && state?.quantityType);
    setValue("purchaseAmount", state && state?.purchaseAmount);
    setValue("transport", state && state?.transport);
    setValue("gst", state && state?.gst);
    setValue("otherAmount", state && state?.otherAmount);
    setValue("total", state && state?.total);
    setValue("invoiceNumber", state && state?.invoiceNumber);
    setValue("transactionMode", state && state?.transactionMode);
    setValue("transactionStatus", state && state?.transactionStatus);
    setValue("purchaseDate", state && state?.purchaseDate);
  }, [state]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    // First argument: function that makes the actual request
    // Second argument (optional): Config object
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/purchases/addPurchase`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (data) => {
        console.log("Data_formate", data?.data);
        // Handle successful mutation
        alert("Purchase Added Successfully!");
        navigate("/Purchase/PurchaseTable");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/purchases/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // Handle successful mutation
        alert("Purchase Update Successfully!");

        navigate("/Purchase/PurchaseTable");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const getPurchaseProductslist = useQuery(
    "getPurchaseProduct_purchase",
    getPurchaseProducts
  );
  const onSubmit = async (data) => {
    console.log("PurchangeForm", data);
    // event.preventDefault();4
    let formData;
    state && state.id !== null
      ? (formData = {
          productName: data?.productName,
          quantity: Number(data?.quantity),
          quantityType: data?.quantityType,
          purchaseAmount: Number(data?.purchaseAmount),
          transport: Number(data?.transport),
          gst: Number(data?.gst),
          otherAmount: Number(data?.otherAmount),
          total: Number(data?.total),
          invoiceNumber: data?.invoiceNumber,
          transactionMode: data?.transactionMode,
          transactionStatus: data?.transactionStatus,
          id: state?.id,
          purchaseDate: data?.purchaseDate ? data?.purchaseDate : new Date(),
        })
      : (formData = {
          productName: data?.productName,
          quantity: Number(data?.quantity),
          quantityType: data?.quantityType,
          purchaseAmount: Number(data?.purchaseAmount),
          transport: Number(data?.transport),
          gst: Number(data?.gst),
          otherAmount: Number(data?.otherAmount),
          total: Number(data?.total),
          invoiceNumber: data?.invoiceNumber,
          transactionMode: data?.transactionMode,
          transactionStatus: data?.transactionStatus,
          purchaseDate: data?.purchaseDate
            ? moment(data?.purchaseDate).format("YYYY-MM-DD")
            : new Date().format("YYYY-MM-DD"),
        });
    if (state && state?.id) {
      updata.mutate(formData);
    } else {
      mutate(formData);
    }
  };
  // let newArray =
  //   getPurchaseProducts.data &&
  //   getPurchaseProducts?.data?.data.filter((item) => item !== null);
  console.log(
    "PurchangeForm_load",
    getPurchaseProductslist.data && getPurchaseProductslist?.data?.data,

  );
  let { productName,quantityType } = watch();

  const products = [
    {
      id: 1,
      productNameEnglish: "Product A",
      quantityType: "Piece",
    },
    {
      id: 2,
      productNameEnglish: "Product B",
      quantityType: "Piece",
    },
    {
      id: 3,
      productNameEnglish: null,
      quantityType: "Piece",
    },
    {
      id: 4,
      productNameEnglish: "Product D",
      quantityType: null,
    },
  ];


  // const productsWithoutNulls = getPurchaseProductslist&&getPurchaseProductslist.data &&
  // getPurchaseProductslist?.data?.data.filter((product) =>
  //   Object.values(product).every((value) => value !== null)
  // );

  const productNames = getPurchaseProductslist&&getPurchaseProductslist.data &&
  getPurchaseProductslist?.data?.data.map(
    (product) => product.productNameEnglish
  );
  const filteredProducts = getPurchaseProductslist&&getPurchaseProductslist.data &&
  getPurchaseProductslist?.data?.data.filter(
    (product) => product.productNameEnglish === productName
  );

  let {
    purchaseAmount,
    transport,
    otherAmount,
    gst,
    quantity,
    transactionMode,
    transactionStatus,
  } = watch();
  useEffect(() => {
    if (
      purchaseAmount !== undefined &&
      transport !== undefined &&
      otherAmount !== undefined &&
      gst !== undefined
    ) {
      let a =
        // Number(purchaseAmount) +
        Number(transport) + Number(otherAmount) + Number(gst);
      setValue("total", a + quantity * Number(purchaseAmount));
    } else {
    }
    setValue(
      "transactionStatus",
      transactionMode == "debit" ? "pending" : "Completed"
    );
  }, [
    purchaseAmount,
    transport,
    otherAmount,
    gst,
    quantity,
    transactionMode,
    transactionStatus,
  ]);

  useEffect(() => {
    if (filteredProducts&&filteredProducts.length > 0) {

      setValue("quantityType", filteredProducts[0]?.quantityType);
    }
  }, [filteredProducts, setValue]);
  return (
    <>
      <div className="main-container">
        <div>
          <div className="BillingAddHeader">
            <div className="Billing-Add-GoBackBtn">
              <img
                src={Goback}
                alt="no-image"
                onClick={() => navigate(-1)}
              ></img>
            </div>

            <div className="billing-heading-viewblock">
              <div>
                <h3 className="Billng-Add-Heading">Purchase Add</h3>
              </div>

              <div className="Billing-Add-ViewBlock">
                <button
                  className="Billing-Add-ViewBtn"
                  onClick={() => {
                    navigate("/Purchase/PurchaseTable");
                  }}
                >
                  View Purchase
                </button>
              </div>
            </div>
          </div>
          <div className="BillingAddMainBlock" style={{ overflow: "hidden" }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="PurchaseAddFormBlock"
            >
              <div>
                <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                >
                  {/* <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Select Product
                    </label>
                    <Controller
                      control={control}
                      name="productName"
                    
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Search Product"
                          type="text"
                          list="products"
                          // onChange={handleChange}
                        />
                      )}
                      rules={{ required: true }}
                    />
                     <datalist id="products">
                                    {newArray?.map((product) => {
                                      // console.log("product name : ",product)
                                      return <option value={product}></option>;
                                    })}
                                  </datalist>

                  </div> */}
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">
                      Select Product
                    </label>
                    <Controller
                      control={control}
                      // name={`userForm.${index}.Product`}

                      name="productName"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState,
                      }) => (
                        <input
                          type="text"
                          list="data"
                          value={value}
                          onChange={onChange}
                          className="billing_input_field"
                          style={
                            fieldState?.error ? { borderColor: "red" } : {}
                          }
                        />
                      )}
                      rules={{ required: true }}
                    />

                    <datalist id="data">
                      {productNames&&productNames?.map((product, key) => (
                        <option key={key} value={product} />
                      ))}
                    </datalist>
                  </div>
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Quantity Type
                    </label>
                    <Controller
                      control={control}
                      name="quantityType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                        className="billing_input_field"

                          value={quantityType}
                          onChange={onChange}
                          placeholder="Quantity Type"
                          type="text"
                        />
                        // <BillingAddInputField
                        //   {...field}
                        //   placeholder="Quantity Type"
                        //   type="text"
                        //   // value={quantityType}
                        // />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">Quantity</label>
                    <Controller
                      control={control}
                      name="quantity"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Quantity"
                          type="text"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                >
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">
                      Product Amount
                    </label>
                    <Controller
                      control={control}
                      name="purchaseAmount"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Product Amount"
                          type="tele"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Transport</label>
                    <Controller
                      control={control}
                      name="transport"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Transport"
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">GST</label>
                    <Controller
                      control={control}
                      name="gst"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="GST Amount"
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Other Amount</label>
                    <Controller
                      control={control}
                      name="otherAmount"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Other Amount"
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Total Amount</label>
                    <Controller
                      control={control}
                      name="total"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Total Amount"
                          type="Number"
                        />
                      )}
                    />
                  </div>
                  <div
                    className="BillingAddInputField"
                    style={{ paddingRight: "12px" }}
                  >
                    <label className="BillingAddInputLabel">
                      Purchase Date
                    </label>
                    <Controller
                      control={control}
                      name="purchaseDate"
                      render={({ field }) => (
                        <DatePicker
                          className="billing_input_field "
                          {...field}
                          placeholderText="PurchaseDate"
                          selected={field.value} // Value from React Hook Form
                          onChange={(date) => field.onChange(date)}
                          maxDate={new Date()}
                          // Event handler for onChange
                        />
                      )}
                    />
                  </div>
                  {/* <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">Status</label>
                    <Controller
                      control={control}
                      name="Status"
                      render={(field) => (
                        <Select
                          className="BillingAddStatus"
                          placeholder="Select"
                          {...field}
                        >
                          <MenuItem value="Completed">Completed</MenuItem>
                          <MenuItem value="Incompleted">Incompleted</MenuItem>
                          <MenuItem value="Canceled">Canceled</MenuItem>
                        </Select>
                      )}
                    />
                  </div> */}
                </div>

                {/*  */}
                <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                >
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">Invoice No</label>
                    <Controller
                      control={control}
                      name="invoiceNumber"
                      render={(field) => (
                        <BillingAddInputField
                          {...field}
                          placeholder="Invoiceno"
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">
                      Transaction Mode
                    </label>
                    <Controller
                      control={control}
                      name="transactionMode"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="billing_input_field"
                          placeholder="Select"
                          style={{
                            "text-transform": "capitalize",
                            // borderRadius: "5px",
                            // border: "none",
                            // padding: "5px 10px",
                          }}
                          // defaultValue="active"
                          // options={options}
                          // defaultValue={options[1]}
                        >
                          <option>None</option>
                          <option value="Online">Online</option>
                          <option value="Cash">Cash</option>
                          <option value="debit">Debit</option>
                        </select>
                        // <Select
                        //   className="BillingAddStatus"
                        //   placeholder="Select"
                        //   {...field}
                        // >
                        //   <MenuItem value="Online">Online</MenuItem>
                        //   <MenuItem value="Cash">Cash</MenuItem>
                        //   <MenuItem value="Credit">Credit</MenuItem>
                        //  </Select>
                      )}
                    />
                  </div>
                  <div className="BillingAddInputField">
                    <label className="BillingAddInputLabel">
                      Transaction Status
                    </label>
                    <Controller
                      control={control}
                      name="transactionStatus"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="billing_input_field"
                          style={{
                            "text-transform": "capitalize",
                            // borderRadius: "5px",
                            // border: "none",
                            // padding: "5px 10px",
                          }}
                        >
                          <option>Select</option>
                          <option value="pending">Pending</option>
                          <option value="Completed">Completed</option>
                        </select>
                        // <Select
                        //   className="BillingAddStatus"
                        //   placeholder="Select"
                        //   {...field}
                        // >
                        //   <MenuItem value="Credit">Credit</MenuItem>
                        //   <MenuItem value="Completed">Completed</MenuItem>
                        //  </Select>
                      )}
                    />
                  </div>

                  {/* <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">Status</label>
                    <Controller
                      control={control}
                      name="Status"
                      render={(field) => (
                        <Select
                          className="BillingAddStatus"
                          placeholder="Select"
                          {...field}
                        >
                          <MenuItem value="Completed">Completed</MenuItem>
                          <MenuItem value="Incompleted">Incompleted</MenuItem>
                          <MenuItem value="Canceled">Canceled</MenuItem>
                        </Select>
                      )}
                    />
                  </div> */}
                </div>
                {/*  */}
                <div style={{ padding: "0px 20px 20px", marginBottom: "30px" }}>
                  <div>
                    <button
                      type="submit"
                      className="BillingAddSubmitBtn"
                      disabled={isLoading ? true : false}
                    >
                      {}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseForm;
