// import React from 'react'

// export default function SingleBarchart() {
//   return (
//     <div>SingleBarchart</div>
//   )
// }
import React from 'react';
import { Bar } from 'react-chartjs-2';

const SingleBarchart = ({data}) => {


  const options = {
    title: {
      display: true,
      text: 'Sales by Month',
      fontSize: 20,
    },
    legend: {
      display: true,
      position: 'top',
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  return (
    <div style={{padding:'20px'}}>
      <Bar
        data={data}
        options={options}
      />
    </div>
  );
};

export default SingleBarchart;
