import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import InputField from "./InputField";
import ProductHeader from "./ProductHeader";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// import {postData} from '${process.env.REACT_APP_HOST}/products/addProduct'
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { useMutation, useQuery } from "react-query";
import { translate } from "@vitalets/google-translate-api";

export default function AddProduct() {
  // const dispatch=useDispatch()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  //  .log('register',register);
  const [startDate, setStartDate] = useState(null);
  

  const [base64Image, setBase64Image] = useState(null);
  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const retun=base64String.replace("data:image/jpeg;base64,", "");
        setBase64Image(retun);
        // setBase64Image(base64String);
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };
  const navigate = useNavigate();

  const { state } = useLocation();
  let base64String = state && state?.productImage;
  console.log("state?.productImage");
  useEffect(() => {
    setValue("productNameEnglish", state?.productNameEnglish);
    setValue("productImage", state?.productImage);
    setValue("productNameTamil", state?.productNameTamil);
    setValue("quantity", state?.quantity);
    setValue("quantityType", state?.quantityType);
    setValue("discountOnUnitPrice", state?.discountOnUnitPrice);
    // setValue("OfferOnUnitPrice", state?.OfferOnUnitPrice);
    setValue("offerSelectFromTo", state?.offerSelectFromTo);
    setValue("unitPrice", state?.unitPrice);
    setValue("actualUnitPrice", state?.actualUnitPrice);
    setValue("stock", state?.stock);
  }, [state]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/products/addProduct`,
        postData
      ),
    {
      onSuccess: (data) => {
        // console.log('SuccessFull',data?.data);
        alert('Product Added Successfully!!');
        reset();
        navigate("/ProductView");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/products/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (data) => {
        // Handle successful mutation
        alert("Product Update Successfully");
        reset();
        navigate("/ProductView");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const Submit = async (data) => {
    let formData = {
      productNameEnglish: data?.productNameEnglish,
      productImage:
        base64Image == null ? state && state?.productImage : base64Image,
      discountOnUnitPrice: data?.discountOnUnitPrice,
      // offerOnUnitPrice: data?.OfferOnUnitPrice,
      offerSelectFromTo: data?.offerSelectFromTo
        ? data?.offerSelectFromTo
        : new Date(),
      productNameTamil: data?.productNameTamil,
      unitPrice: data?.unitPrice,
      quantity: data?.quantity,
      quantityType: data?.quantityType,
      actualUnitPrice: data?.actualUnitPrice,
      stock: data?.stock,
    };
    if (state && state?.id) {
      updata.mutate(formData);
    } else {
      mutate(formData);
    }

    console.log("For_data", formData);
  };

  // Define mutation function using useMutation hook
  const { productNameEnglish } = watch();
  console.log("productNameEnglish", productNameEnglish);
  // console.log('productImage',base64Image==null&&!state&&state?.id,base64Image==null)

  const handleEnglishChange = async (e) => {
    const text = e.target.value;
    // setEnglishText(text);
    console.log("tamil", text);
    try {
      // Translate the English text to Tamil
      const translation = await translate(text, { to: "ta" });
      console.log("VL", translation);
      setValue("productNameTamil", translation);
    } catch (error) {
      console.log("Translation error:", error);
    }
  };
  const [isImages, setIsImages] = useState(state && state.productImage);

  const handlerImage = () => {
    const confirmDelete = window.confirm("Are you want to Change the Image?");
    if (confirmDelete) {
      setIsImages(false);
    }
  };

  let { discountOnUnitPrice } = watch();
  let { quantity } = watch();
  let { unitPrice } = watch();

  const productDivValue = quantity * unitPrice - discountOnUnitPrice;

  console.log("productDivValue", productDivValue);
  useEffect(() => {
    setValue("actualUnitPrice", productDivValue);
  }, [productDivValue]);

  return (
    <div className="main-container">
      <ProductHeader back={true} productView={true} label="Add Product" />

      <div
        className="input-container"
        style={{ height: "600px", margin: "40px" }}
      >
        <form onSubmit={handleSubmit(Submit)}>
          <div className="input-field">
            <div>
              <div className="ProductDivField">
                <label className="ProductLabel">Product Name English</label>
                <Controller
                  control={control}
                  name="productNameEnglish"
                  render={(field) => (
                    // <input  className='input-componentsField '  placeholder='Enter Product Name English' type='text' onChange={(e)=>onChange(handleEnglishChange(e))} value={value}/>
                    <InputField
                      {...field}
                      required={true}
                      type="text"
                      placeholder="Enter Product Name English"
                      onChange={(e) => handleEnglishChange(e)}
                    />
                  )}
                  rules={{ required: true }}
                />
                {/* <InputField {...register("productName")} placeholder="Enter Product Name English" type="text" /> */}
              </div>
              <div className="ProductDivField">
                  <label className="ProductLabel">Quantity Type</label>
                  <Controller
                    control={control}
                    name="quantityType"
                    render={(field) => (
                      <InputField
                        {...field}
                        placeholder="Quantity Type"
                        type="type"
                      />
                    )}
                  />
                </div>
              <div className="ProductDivField">
                <label className="ProductLabel">Product Image</label>
                {isImages ? (
                  <p
                    className="input-componentsField "
                    onClick={() => handlerImage()}
                  >
                    {base64String
                      .substring(5, base64String.indexOf(";"))
                      .replace("/", ".")}
                  </p>
                ) : (
                  <Controller
                    control={control}
                    name="productImage"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        className="input-componentsField "
                        accept="image/*"
                        type="file"
                        onChange={(e) => onChange(handleImageInputChange(e))}
                      />
                      // <InputField  {...field} placeholder="0.00 " type="file" />
                    )}
                  />
                )}
                {/* <input className="chooseFileField" {...register("ProductImage")} placeholder="0.00 " type="file"/> */}
                {/* <InputField className="chooseFileField" {...register("ProductImage")} type="file" /> */}
              </div>
              <div className="ProductDivField">
                <label className="ProductLabel">Unit Price</label>
                <Controller
                  control={control}
                  name="unitPrice"
                  render={(field) => (
                    <InputField {...field} placeholder="0.00 " type="number" />
                  )}
                  rules={{ required: true }}
                />
                {/* <InputField type="file" /> */}
              </div>
              <div className="ProductDivField">
                <label className="ProductLabel">Actual Unit Price</label>
                <Controller
                  control={control}
                  name="actualUnitPrice"
                  render={(field) => (
                    <InputField
                      {...field}
                      placeholder="Actual Unit Price"
                      type="Number"
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div>
            
                <div className="ProductDivField">
                  <label className="ProductLabel">Quantity</label>
                  <Controller
                    control={control}
                    name="quantity"
                    render={(field) => (
                      <InputField
                        {...field}
                        placeholder="Quantity"
                        type="type"
                      />
                    )}
                  />
                </div>
                {/* Dis */}

                <div className="ProductDivField">
                  <label className="ProductLabel">Discount on Unit Price</label>
                  <Controller
                    control={control}
                    name="discountOnUnitPrice"
                    render={(field) => (
                      <InputField {...field} placeholder="0.00" type="Number" />
                    )}
                  />
                </div>
                <div className="ProductDivField">
                  <label className="ProductLabel">Offer Select Date</label>
                  <Controller
                    control={control}
                    name="offerSelectFromTo"
                    render={({ field }) => (
                      <DatePicker
                        className="input-datePickerField"
                        {...field}
                        placeholderText="Product Date"
                        selected={field.value} // Value from React Hook Form
                        onChange={(date) => field.onChange(date)} // Event handler for onChange
                        maxDate={new Date()}
                      />
                    )}
                    // render={({ field: { onChange, onBlur, value, ref } }) => (
                    //   // <InputField {...field} placeholder="Active" type="text" />

                    //   <DatePicker
                    //     className="input-datePickerField"
                    //     selected={startDate}
                    //     selectsRange
                    //     selected
                    //     startDate={value && value[0]}
                    //     endDate={value && value[1]}
                    //     placeholderText="  From   -    To"
                    //     // dateFormat={displayFormat}
                    //     onChange={onChange}
                    //     // locale={selectLocale(locale)}
                    //     // customInput={<CustomInput />}
                    //   />
                    // )}
                  />
                </div>
                <div className="ProductDivField">
                  <label className="ProductLabel">Stock</label>

                  <Controller
                    control={control}
                    name="stock"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      // <InputField
                      //   {...field}
                      //   placeholder="Active "
                      //   type="text"
                      // />
                      <select
                        value={value}
                        onChange={onChange}
                        // {...field}
                        className="input-datePickerField"
                        style={{ "text-transform": "capitalize" }}
                        // defaultValue="active"
                        // options={options}
                        // defaultValue={options[1]}
                      >
                        <option>none</option>
                        <option value="Available">Available</option>
                        <option value="OutOfstock">Out Of stock</option>
                      </select>
                    )}
                  />
                </div>
                <div className="ProductDivField">
                <label className="ProductLabel"></label>
            <input className="inputSubmit-Btn" type="submit" />
          </div>
              </div>
            </div>
          </div>
     
        </form>
      </div>
    </div>
  );
}
