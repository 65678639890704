import React from "react";
import CustomerRegister from "../component/CustomerRegister";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

export default function Register() {
  const queryClient = new QueryClient();
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <CustomerRegister />
      </QueryClientProvider>
    </div>
  );
}
