import React from 'react'
import AddProduct from '../component/AddProduct'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
export default function ProductForm() {
  const queryClient = new QueryClient()
  return (
 <>
   <QueryClientProvider client={queryClient}>

  <AddProduct/>
   </QueryClientProvider>
 </>
  )
}
