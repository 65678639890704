import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/global.css'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from "./redux/store";
import Sidebars from './component/Sidebar';
import ProductView from './pages/ProductView';
import ProductForm from './pages/ProductForm';
import ProductTable from './pages/ProductTable';
import BillingTable from './pages/BillingTable';
import BillingForm from './pages/BillingForm';
import BillingView from './component/BillingView';
import Dashboard from './pages/Dashboard';
import BillingAdd from './component/BillingAdd';
import BillingInvoice from './pages/BillingInvoice';
import BillingInvoices from './pages/BillingInvoice';
import DashBoard from './component/DashBoard';
import ProductInvoice from './pages/ProductInvoice';
import PurchaseForm from './pages/PurchaseForm';
import OverAllDashboard from './pages/OverAllDashboard';
import PurchaseTable from './pages/PurchaseTable';
import Home from './Home';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // 
  <Provider store={store}>
<Home/>
</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
